import { GridCell } from "@optimizely/axiom";
import { ProductCardWithIcon } from "../ProductCard/ProductCardWithIcon";
import { ProductCard } from "../ProductCard/ProductCard";
import styles from './ProductGrid.module.scss';

export const ProductGrid = ({
    email,
    instancesByProducts = {},
    showIcons = false
}: {
    email: string,
    instancesByProducts: { [key: string]: any },
    showIcons?: boolean
}) => {
    const productNames = Object.keys(instancesByProducts || {});

    return (
        <>
            {productNames.map(productName => {
                const productInstances = instancesByProducts[productName] || [];

                return (
                    <GridCell xlarge={4} large={4} medium={4} small={4} key={productName}  className={styles["product-grid"]}>
                        {showIcons ? <ProductCardWithIcon email={email} product={productName} instances={productInstances} /> : <ProductCard email={email} product={productName} instances={productInstances} />}
                    </GridCell>
                )
            })}
        </>
    );
};

ProductGrid.displayName = "ProductGrid";
