import React, {useCallback, useEffect, useState} from "react";
import {addQueryParam} from "../../utils/addQueryParam";
import * as Icons from "../../icons";
import {Dropdown, Icon as OptimizelyIcon, Input, OverlayWrapper, Popover, Poptip, Button} from "@optimizely/axiom";
import {ArrowDown} from "../Arrow/ArrowDown";
import {ArrowUp} from "../Arrow/ArrowUp";
import classnames from "classnames";
import styles from "./ProductCardWithIcons.module.scss";
import {INVALID_NAVIGATION_URL} from "../../utils/constants";

type ProductCardWithIconProps = {
    email?: string;
    product: string;
    instances: any[];
};

const ProductIconMap: { name: string, productIds: string[], icon: React.ComponentType<React.SVGProps<SVGSVGElement>> | null }[] = [
    {
        "name": "Admin Center",
        "productIds": ["404d1878bae04092a24d9f3a032769be"],
        "icon": Icons.AdminCenter
    },
    {
        "name": "Campaign",
        "productIds": [
            "3a973edddd1c4afcb791cfb83d8227d4",
            "3712a51cc4f241f79511e283f76cbd9d",
            "cc7cbb40ce4a4bd785d07d76bf304efb"
        ],
        "icon": Icons.Campaign
    },
    {
        "name": "CMS - On Prem",
        "productIds": [
            "606144c53dad4dd1a98e7ce6326d8fc8",
        ],
        "icon": Icons.CMS
    },
    {
        "name": "Collaboration",
        "productIds": ["e3469b3f3bf541bba5c6eaeeb9ccdd2e", "a5fc32bbe5a04b02b18d188878c0f2ed"],
        "icon": Icons.Collaboration
    },
    {
        "name": "Configured Commerce",
        "productIds": [
            "f7b88d45d0974d3ca36038a9d3f607db",
            "01976f29b79742c6b69f52822b5dc1f6",
        ],
        "icon": Icons.ConfiguredCommerce
    },
    {
        "name": "Customized Commerce",
        "productIds": [],
        "icon": null
    },
    {
        "name": "Content Management System",
        "productIds": [
            "e0b284faee7a4a0b87c5d40aa56c85b0",
            "055931d7cc73491db3f6e77d8793d58d",
            "4f02877522b242b991a1686c731af885",
            "25f5d05cfb3a4c91a221f814b8bab0af",
            "842b6c64e72a40c0a8cb94d280cdc500",
            "014a3427260046269d67233a94b22ca0"
        ],
        "icon": Icons.CMS
    },
    {
        "name": "Content Marketing Platform",
        "productIds": [
            "1b6ee156236940359189c0e4136753a7",
            "df442b9c51f84e5f92b77afb2bbc833f",
            "4c04614d4191472a9970c29f84ddc092"
        ],
        "icon": Icons.CMP
    },
    {
        "name": "Content Recommendations",
        "productIds": [
            "1964aab067eb44249df71c2900f011c2",
            "26e8e790cefc42b7962196061135f01f",
            "6a2b996bfe1746a1a2b8fe83f10823ac"
        ],
        "icon": Icons.ContentRecommendations
    },
    {
        "name": "Data Platform",
        "productIds": [
            "95dcec3bec0e42838b37f69e3927f2bc",
            "f14eb4a65b3546b598eb505a99f6bc76",
            "03f48b54d0734f2192f9f781d61a12a2"
        ],
        "icon": Icons.DataPlatform
    },
    {
        "name": "Product Recommendations",
        "productIds": [
            "60542b283d18417cbdd03d3934d4caca",
            "e6598e67b6ee40f4a1284dfb678602d6",
            "8456c55c6da04edaba129aba32b52543"
        ],
        "icon": Icons.ProductRecommendations
    },
    {
        "name": "Experimentation",
        "productIds": [
            "5a976e8c6004412b83aca3609ccb347f",
            "feature_experimentation",
            "008213bfa591462f9b54c84b441f1a46",
            "8bfca79fbc0046199a4aa4f34a7e0d97"
        ],
        "icon": Icons.WebExperimentation
    },
    {
        "name": "Search & Navigation",
        "productIds": ["20c8dab8278c470797879f94d5439a14"],
        "icon": Icons.SearchAndNavigation
    },
    {
        "name": "Personalization",
        "productIds": [],
        "icon": Icons.Personalization
    },
    {
        "name": "Digital Asset Management",
        "productIds": [],
        "icon": Icons.DigitalAssetManagement
    },
    {
        "name": "Commerce Mobile",
        "productIds": [],
        "icon": Icons.Mobile
    },
    {
        "name": "Graph",
        "productIds": [
            "b94f6e9573e647dfbddcf59abadd9ffb",
            "644f9f342ae74b7bb997bf5645d61581"
        ],
        "icon": Icons.Graph
    },
    {
        "name": "App Marketplace",
        "productIds": [],
        "icon": Icons.AppMarketplace
    },
    {
        "name": "Developer Portal (CMS SaaS)",
        "productIds": [
            "d19bc7a3d71b4ee09e21d8dcbe0e99bc",
            "76f06394c9c84be7ae4bf8d55c4eea8a",
            "655dc0fe61b946e98a668bc8aef830ac",
            "fc92d9948f444261acd212e2b35bcbf3",
            "7e0d77124e134a7b804c24151acd895e"
        ],
        "icon": Icons.CMS
    },
    {
        "name": "Developer Portal (CMS)",
        "productIds": [
            "8dad4dcfa7384cb8a0c29446ebc6f19f",
            "c5f676567f774786813055f4edaf50ad",
            "0eac68cd67a143e79f1b4de8e23e7df5",
            "0e73841bda7f4af0ab478587e7b46ed6",
            "19986d79be9b487a86584e8bffb8ad4a"
        ],
        "icon": Icons.CMS
    },
    {
        "name": "Reporting",
        "productIds": [
            "c43121f7ada646428242dff77afcbf27",
            "05743fae7cf547fc86456883cc681477"
        ],
        "icon": null
    },
    {
        "name": "Mission Control",
        "productIds": ["2ce690b32628454bb87d32f94f0fdce0"],
        "icon": null
    }
]

export const ProductCardWithIcon = ({email, instances, product}: ProductCardWithIconProps) => {
    const [{ProductId}] = instances;
    const [filteredInstances, setFilteredInstances] = useState(instances);
    const [searchString, setSearchString] = useState("");
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const filterInstances = useCallback((value: string) => {
        const filtered = instances?.filter(i => {
            return i.InstanceName?.toLowerCase().includes(value.toLowerCase()) ||
                i.InstanceNickname?.toLowerCase().includes(value.toLowerCase());
        });
        setFilteredInstances(filtered);
    }, [instances]);

    useEffect(() => {
        filterInstances(searchString);
    }, [filterInstances, searchString]);

    useEffect(() => {
        if (isDropdownOpen) setSearchString("");
    }, [isDropdownOpen])

    const Icon = ProductIconMap.find(mapping => {
        return mapping.productIds.indexOf(ProductId) > -1 || product.replace("Optimizely ", "") === mapping.name;
    })?.icon || Icons.PlaceholderProductIcon;

    const navigateToInstance = (instance: any) => {
        const {NavigationUrl: url} = instance || {};
        if (!!url && email) {
            window.location.href = addQueryParam({url, param: 'login_hint', value: email});
        }
    };

    const handleInstanceClick = ({instance}: { instance: any }) => {
        if (instance.NavigationUrl) {
            navigateToInstance(instance);
        }
    }

    const handleProductClick = (event: React.MouseEvent<HTMLDivElement>) => {
        if (instances.length === 1) {
            const [instance] = instances;
            navigateToInstance(instance);
        }
    }

    const checkPrevent = ({event, shouldPrevent}: { event: any, shouldPrevent: boolean }) => {
        if (shouldPrevent) {
            preventDropdownClose(event);
        }
    };

    const preventDropdownClose = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
    }

    const singleInstanceWithNoNavigationUrl = instances.length === 1 && !instances[0].NavigationUrl;

    const dropdownClasses = classnames(styles["product-card__container"], {
        [styles["product-card__container--active"]]: isDropdownOpen,
        [styles["product-card__container--disabled"]]: singleInstanceWithNoNavigationUrl
    });

    if (singleInstanceWithNoNavigationUrl) {
        return (
            <Poptip position="bottom" content={INVALID_NAVIGATION_URL} className="" trigger="mouseenter">
                <div className={dropdownClasses}>
                    <div className={styles['product-card__icon-container']}>
                        <Icon/>
                    </div>
                    <div className={styles["product-card__product-name"]}>{product}</div>
                </div>
            </Poptip>
        )
    }

    return (
        <Dropdown
            className={dropdownClasses}
            /* eslint-disable-next-line react/style-prop-object */
            style="plain"
            fullWidth
            onClick={handleProductClick}
            buttonContent={<>
                <div className={styles['product-card__icon-container']}>
                    <Icon/>
                    {instances.length > 1 && (
                        <>{isDropdownOpen ? <ArrowUp/> : <ArrowDown/>}</>
                    )}
                </div>
                <div className={styles["product-card__product-name"]}>{product}</div>
            </>}
        >
            {instances.length > 1 && (
                <Dropdown.Contents ref={setIsDropdownOpen}>
                    {instances.length > 10 && (
                        <div className="push">
                            <Input
                                isFilter
                                id="instance-search"
                                onClick={(e) => e.stopPropagation()}
                                onInput={(e) => {
                                    setSearchString(e.target.value)
                                }}
                                placeholder={"Search..."}
                                type="search"
                            />
                        </div>
                    )}
                    {filteredInstances.map((instance, index) => {
                        const {InstanceId, InstanceNickname, InstanceName, NavigationUrl} = instance;
                        const itemClasses = classnames(styles['product-card__list-item'], {
                            [styles['product-card__list-item--justify-between']]: !NavigationUrl
                        });

                        if (NavigationUrl) {
                            return (
                                <Dropdown.ListItem key={InstanceId} className={itemClasses}
                                                   onClick={(e) => checkPrevent({
                                                       event: e,
                                                       shouldPrevent: !NavigationUrl
                                                   })}>
                                    <Dropdown.BlockLink
                                        onClick={() => handleInstanceClick({instance})}
                                    >
                                        {InstanceNickname || InstanceName}
                                    </Dropdown.BlockLink>

                                </Dropdown.ListItem>
                            )
                        }

                        return (
                            <OverlayWrapper
                                behavior="hover"
                                horizontalAttachment="left"
                                className="width--1-1"
                                key={InstanceId}
                                overlay={
                                    <Popover title={""} className={styles['product-card__popover']}>
                                        {INVALID_NAVIGATION_URL}
                                    </Popover>
                                }
                                verticalAttachment="middle"
                            >
                                <Dropdown.ListItem className={itemClasses}
                                                   onClick={(e) => checkPrevent({
                                                       event: e,
                                                       shouldPrevent: !NavigationUrl
                                                   })}>
                                    <Dropdown.BlockLink
                                        onClick={() => handleInstanceClick({instance})}>
                                        {instance.InstanceNickname || instance.InstanceName}
                                        {!NavigationUrl && <OptimizelyIcon size="small" name="triangle-exclamation"/>}
                                    </Dropdown.BlockLink>
                                </Dropdown.ListItem>
                            </OverlayWrapper>
                        );
                    })}
                    {!filteredInstances?.length && (
                        <Dropdown.ListItem id="no-results" onClick={(e) => e.stopPropagation()}>
                            <Button
                                /* eslint-disable-next-line react/style-prop-object */
                                style="plain"
                                className={styles["product-card__no-results"]}
                                onClick={() => setSearchString("")}
                            >
                                No instances match your search. Reset results.
                            </Button>
                        </Dropdown.ListItem>
                    )}
                </Dropdown.Contents>
            )}
        </Dropdown>
    )
};

ProductCardWithIcon.displayName = "ProductCardWithIcon";