import React from "react";
import { HelpAndResources } from "../HelpAndResources/HelpAndResources";

import styles from "./DashboardLayout.module.scss"

export const DashboardLayout = ({ children }: { children: React.ReactNode }) => {
    return (
        <div className={"noselect " + styles["dashboard-layout"]}>
                <div className={styles["dashboard-layout__main-content"]}>
                    {children}
                </div>
                <div className={styles["dashboard-layout__help-column"]}>
                    <HelpAndResources/>
                    <div id="marketing-space"/>
                </div>
        </div>
    );
};

DashboardLayout.displayName = "DashboardLayout";